import axios from 'axios'

const modalFormClient = {
  validateForm: (formFields = {}, requiredFields = []) => {
    const missed = []
    const empties = requiredFields.map(value => {
      const missing = formFields[value] != null && formFields[value].length > 0
      missed.push(value)
      return missing
    })
    return empties.indexOf(false) > -1 ? missed : [];
  },
  sendForm: async (leadURL, body) => {
    try {
      const result = await axios.post(leadURL, JSON.stringify(body))
      return { result }
    } catch (error) {
      console.log(error)
      return { error }
    }
  }
}

export default modalFormClient